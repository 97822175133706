import './PantallaCargando.scss'
import React from "react";

const PantallaCargando: React.FC = () => {
    return (
        <div className='pantallaCargando'>
            <div className='spinner-border'/>
        </div>
    )
}

export default PantallaCargando