import './PaginaDefault.scss'
import React from "react";
import config from "./config";

const PaginaDefault: React.FC = () => {
    return (
        <div className='paginaDefault'>
            <div className='text-center'>
                <div className='titulo'><span className='rojo'>gs.</span>bares</div>
                <a href={config.web} target='_blank' rel='noreferrer'>
                    <i className='fas fa-external-link-alt me-2'/>Más información
                </a>
            </div>
        </div>
    )
}

export default PaginaDefault