import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "./App";
import Menu from "./Menu";
import PaginaDefault from "./PaginaDefault";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App/>}>
                    <Route index element={<PaginaDefault/>}/>
                    <Route path=':nomCliente' element={<Menu/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
