import React from "react";
import {CategoriaMenu} from "./tiposMenu";
import CategoriaProveedor from "./CategoriaProveedor";
import CategoriaProducto from "./CategoriaProducto";
import CategoriaCerveza from "./CategoriaCerveza";

type Props = {
    datos: Array<CategoriaMenu>
}


const CategoriasMenu: React.FC<Props> = ({datos}) => {
    if (!datos.length) return <React.Fragment/>

    return (
        <>
            {datos.map((c, k) => (
                <div key={k}>
                    {!c.agrupamiento_proveedor && (
                        <div className='tituloCategoria'>
                            {c.cervezas.length ? 'Cervezas Tiradas' : c.descripcion}
                        </div>
                    )}
                    {c.cervezas.length ? (
                        <div className='cervezasCategoria'>
                            {c.cervezas.map((c, k) => (
                                <CategoriaCerveza datos={c} key={k}/>
                            ))}
                        </div>
                    ) : c.agrupamiento_proveedor ? (
                        <div className='row gx-3 gy-0'>
                            {c.proveedores.length === 1 ? (
                                <CategoriaProveedor datos={c.proveedores[0]}/>
                            ) : (
                                <>
                                    <div className='col-lg-6'>
                                        {c.proveedores
                                            .filter((_, i) => i % 2 === 0)
                                            .map((p, k) => (
                                                <CategoriaProveedor datos={p} key={k}/>
                                            ))}
                                    </div>
                                    <div className='col-lg-6'>
                                        {c.proveedores
                                            .filter((_, i) => i % 2 !== 0)
                                            .map((p, k) => (
                                                <CategoriaProveedor datos={p} key={k}/>
                                            ))}
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className='productosCategoria'>
                            {c.productos.map((p, k) => (
                                <CategoriaProducto datos={p} key={k}/>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </>
    )
}

export default CategoriasMenu