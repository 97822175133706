import React from "react";
import {CervezaTirada} from "./tiposMenu";

type Props = {
    datos: CervezaTirada
}

const CategoriaCerveza: React.FC<Props> = ({datos}) => {
    return (
        <div className='renglonCerveza'>
            <div className='d-flex row align-items-center'>
                <div className='col-md-8'>
                    <div className='tituloCerveza'>
                        {datos.cerveza} <span className='proveedorCerveza'>{datos.proveedor}</span>
                    </div>
                    {datos.descripcion_larga !== '' && (
                        <div className='descripcionCerveza'>{datos.descripcion_larga}</div>
                    )}
                </div>
                <div className='col-md-4'>
                    <div>
                        {datos.precio_1 !== '' && (
                            <div className='d-flex'>
                                <div className='flex-grow-1'>{datos.desc_1}</div>
                                <div className='ps-3 precioCerveza'>{datos.precio_1}</div>
                            </div>
                        )}
                        {datos.precio_2 !== '' && (
                            <div className='d-flex'>
                                <div className='flex-grow-1'>{datos.desc_2}</div>
                                <div className='ps-3 precioCerveza'>{datos.precio_2}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoriaCerveza