import React from "react";
import {ProveedorCategoria} from "./tiposMenu";

type Props = {
    datos: ProveedorCategoria
}

const CategoriaProveedor: React.FC<Props> = ({datos}) => {
    return (
        <div className='cuadroProveedor'>
            <div className='tituloProveedor'>
                {datos.logo === '' ? (
                    <>{datos.descripcion}</>
                ) : (
                    <img src={datos.logo} alt={datos.descripcion} className='logoProveedor'/>
                )}
            </div>
            {datos.productos.map((p, k) => (
                <div className='d-flex align-items-end' key={k}>
                    <div>{p.descripcion}</div>
                    <div className='flex-grow-1 d-flex'>
                        <div className='flex-grow-1 lineaPrecios mb-2'></div>
                    </div>
                    <div className='text-nowrap'>{p.precio}</div>
                </div>
            ))}
        </div>
    )
}

export default CategoriaProveedor