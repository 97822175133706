import React from "react";
import {GrupoMenu} from "./tiposMenu";
import CategoriasMenu from "./CategoriasMenu";

type Props = {
    datos: Array<GrupoMenu>
}

const GruposMenu: React.FC<Props> = ({datos}) => {
    return (
        <>
            {datos.map((g, k) => g.categorias.length? (
                <div key={k}>
                    <div className='tituloGrupo'>{g.descripcion}</div>
                    <CategoriasMenu datos={g.categorias} key={k}/>
                </div>
            ) : (
                <React.Fragment/>
            ))}
        </>
    )
}

export default GruposMenu