import React from "react";
import {ProductoCategoria} from "./tiposMenu";

type Props = {
    datos: ProductoCategoria
}

const CategoriaProducto: React.FC<Props> = ({datos}) => {
    return (
        <>
            <div className='d-flex align-items-end'>
                <div>
                    <div>{datos.descripcion}</div>
                </div>
                <div className='flex-grow-1 d-flex'>
                    <div className='flex-grow-1 lineaPrecios mb-2'></div>
                </div>
                <div className='text-nowrap'>{datos.precio}</div>
            </div>
            {datos.descripcion_larga !== '' && (
                <div className='descripcionProducto'>{datos.descripcion_larga}</div>
            )}
        </>
    )
}

export default CategoriaProducto