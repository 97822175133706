import React from 'react';
import './App.scss';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import {Outlet} from "react-router-dom";

function App() {
    return (
        <div className='App'>
            <Outlet/>
        </div>
    );
}

export default App;
