import './Menu.scss'
import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DatosMenu} from "./tiposMenu";
import config from "./config";
import PantallaCargando from "./PantallaCargando";
import EnlacesMenu from "./EnlacesMenu";
import GruposMenu from "./GruposMenu";

type MenuParams = {
    nomCliente: string
}

const Menu: React.FC = () => {
    const [datos, setDatos] = useState<DatosMenu | undefined>(undefined)
    const navigate = useNavigate()
    const params = useParams<MenuParams>()
    const nomCliente = params.nomCliente

    const cargarDatos = useCallback(async () => {
        if (nomCliente === undefined) return
        await fetch(config.api + 'publico?cliente=' + encodeURIComponent(nomCliente), {
            method: 'get'
        })
            .then(res => res.json())
            .then(res => {
                if (res.resultado === false) {
                    console.log(res.mensaje)
                    navigate('/')
                } else {
                    setDatos(res)
                }
            })
            .catch(err => alert(err))
    }, [navigate, nomCliente])

    useEffect(() => {
        cargarDatos().catch(err => alert(err))
    }, [cargarDatos])

    useEffect(() => {
        if (datos !== undefined) document.title = datos.cliente.cliente
    }, [datos])

    if (datos === undefined) return <PantallaCargando/>

    const cli = datos.cliente

    return (
        <div className='st-cuenca'>
            <div className='fondoMenu'>
                <div className='container'>
                    <div className='nombreCliente'>
                        {datos.logo === '' ? (
                            <>{cli.cliente}</>
                        ) : (
                            <img src={datos.logo} alt={cli.cliente} className='logoCliente'/>
                        )}
                    </div>
                    <div className='infoCliente'>
                        {cli.texto !== '' && (<div>{cli.texto}</div>)}
                        {cli.direccion !== '' && (<div>{cli.direccion}</div>)}
                        <div className='row'>
                            {cli.telefono !== '' && (
                                <div className='col-md'><i className='fas fa-phone me-2'/>{cli.telefono}</div>
                            )}
                            {cli.whatsapp !== '' && (
                                <div className='col-md'><i className='fab fa-whatsapp me-2'/>{cli.whatsapp}</div>
                            )}
                            {cli.email !== '' && (
                                <div className='col-md'><i className='fas fa-envelope me-2'/>{cli.email}</div>
                            )}
                        </div>
                    </div>
                    <EnlacesMenu datos={datos.enlaces}/>
                    <GruposMenu datos={datos.grupos}/>
                    <div className='footer row'>
                        <div className='col-lg-6'>{cli.cliente}</div>
                        <div className='col-lg-6'>{cli.direccion}</div>
                    </div>
                    <div className='poweredBy'>
                        <i className='fas fa-external-link-alt me-2'/>
                        <a href={config.web} target='_blank' rel='noreferrer'>gs.bares</a> menú online
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu