import React from "react";
import {EnlaceCliente} from "./tiposMenu";

type Props = {
    datos: Array<EnlaceCliente>
}

const EnlacesMenu: React.FC<Props> = ({datos}) => {
    const anchoCol = (): number => {
        switch (datos.length) {
            case 1:
                return 12
            case 2:
                return 6
            default:
                return 4
        }
    }

    const iconoEnlace = (tipo: number): string => {
        switch (tipo) {
            case 2: // Web
                return 'fas fa-earth-americas'
            case 3: // Instagram
                return 'fab fa-instagram'
            case 4: // Facebook
                return 'fab fa-facebook'
            case 5: // Twitter
                return 'fab fa-twitter'
            case 6: // TikTok
                return 'fab fa-tiktok'
            default:
                return 'fas fa-link'
        }
    }

    const urlEnlace = (tipo: number): string => {
        switch (tipo) {
            case 3:
                return 'https://www.instagram.com/'
            case 4:
                return 'https://www.facebook.com/'
            case 5:
                return 'https://www.twitter.com/'
            case 6:
            default:
                return ''
        }
    }

    if (!datos.length) return <React.Fragment/>

    return (
        <div className='row enlacesMenu mb-3'>
            {datos.map((e, k) => (
                <div className={`col-lg-${anchoCol()}`} key={k}>
                    <i className={`${iconoEnlace(e.tipo)} me-2`}/>
                    <a href={urlEnlace(e.tipo) + e.enlace} target='_blank' rel='noreferrer'>{e.enlace}</a>
                </div>
            ))}
        </div>
    )
}

export default EnlacesMenu